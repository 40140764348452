.filterOption {
  composes: bg-color--gray from '../../../../../../mixins/color.module.css';
  composes: display--flex from '../../../../../../mixins/display.module.css';
  composes: cursor--pointer from '../../../../../../mixins/misc.module.css';
  align-items: flex-start;
  border-bottom: 2px solid #ddd;
  font-size: 1.4rem;
  padding: 5px 10px;
}
.filterOptionBox {
  composes: bg-color--white from '../../../../../../mixins/color.module.css';
  composes: display--inline-block from '../../../../../../mixins/display.module.css';
  border: 2px solid#ccc;
  height: 20px;
  flex: 0 0 20px;
  margin-right: 10px;
  transition: border .2s ease;
  max-width: 20px;
}
.filterOption:hover .filterOptionBox {
  border: 2px solid#999;
}