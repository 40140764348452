.button {
  composes: button--round from '../../../mixins/button.module.css';
  composes: bg-color--main from '../../../mixins/color.module.css';
  box-shadow: 0px 3px 2px 0px #006699;
  right: 90px;
  bottom: 70px;
}
.button::before {
  background: #006699;
}
.button:hover {
  box-shadow: 0px 0px 2px 0px #2788ba;
}

.image {
  composes: button-image from '../../../mixins/button.module.css';
}
