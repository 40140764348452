.filter {
  composes: bg-color--white from '../../../../../mixins/color.module.css';
  composes: position--fixed from '../../../../../mixins/position.module.css';
  border-radius: 5px;
  box-shadow: 0px 3px 1px 0px rgba(0,0,0,.3);
  padding: 10px 15px;
  right: 10px;
  top: 10px;
  width: 240px;
  z-index: 10;
}
.filterTitle {
  composes: font-weight--800 from '../../../../../mixins/typography.module.css';
  border-bottom: 2px solid #ddd;
  padding: 5px 0;
  text-transform: uppercase;
}
.filterTypes {
  padding: 10px 0;
}
.filterType {
  composes: bg-color--gray from '../../../../../mixins/color.module.css';
  composes: cursor--pointer from '../../../../../mixins/misc.module.css';
  composes: font-weight--600 from '../../../../../mixins/typography.module.css';
  border-bottom: 2px solid #ddd;
  font-size: 1.4rem;
  padding: 5px 10px;
  transition: padding .2s ease;
}
.selected {
  composes: color--white from '../../../../../mixins/color.module.css';
  composes: bg-color--main from '../../../../../mixins/color.module.css';
  border-bottom: 2px solid #006699;
}
.filterType:not(.selected):hover {
  background: #fff;
  color: #666;
  padding-left: 20px;
}
.filterOptions {
  padding: 10px 0;
}

@media all and (max-width: 640px){
  .filter {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform .3s ease;
    top: 90px;
    z-index: 20;
  }
  .filterVisible {
    composes: filter;
    transform: translateX(0%);
  }
  .filterTitle {
    display: none;
  }
}

@media all and (max-height: 600px){
  .filter {
    padding: 5px 8px;
  }
  .filterTypes {
    padding: 5px 0;
  }
}