.icon {
  background: url('../../../../assets/trm.png') no-repeat #368636;
  background-position: 50% 45%;
  background-size: 30px 30px;
  border-radius: 50%;
  box-shadow: 0px 3px 1px 0px #1d502c;
  transition: box-shadow .3s ease;
}
.icon:hover {
  box-shadow: 0px 0px 1px 0px #1d502c;
}