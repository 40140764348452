a,abbr,acronym,address,applet,article,b,big,caption,center,cite,code,dd,del,dfn,dl,dt,em,embed,fieldset,footer,form,h1,h2,h3,h4,h5,h6,header,html,i,iframe,img,ins,kbd,label,legend,li,nav,object,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,u,ul,var {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
article,footer,header,nav,section {
  display: block;
}
html {
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}
body {
  line-height: 1.42em;
  overflow-x: hidden;
}
ul {
  list-style: none;
}
a {
  color:inherit;
  text-decoration:none;
}
a:hover,
a:active {
  outline: 0;
}
strong {
  font-weight: 600;
}

img {
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

input[disabled] {
  cursor: default;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.6rem;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}
input {
  line-height: 1.42em;
}
input,
textarea {
  background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
}
*,
*:before,
*:after{
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
}

html {
  font-size: 62.5%;
}
html,
body {
  background: #fff;
  font-family: 'Lato', sans-serif;
  height: 100vh;
}
body {
  font-size: 1.6rem;
  height: 100%;
}

#map {
  z-index: 10;
}

.unhoverable {
  pointer-events: none !important;
}