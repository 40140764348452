
.bg-color--gray {
  background-color: #eee;
}

.bg-color--main {
  background-color: #2788ba;
}

.bg-color--white {
  background-color: #fff;
}

.color--white {
  color: #fff;
}