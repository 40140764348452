.button {
  composes: button--round from '../../../../../../mixins/button.module.css';
  composes: bg-color--main from '../../../../../../mixins/color.module.css';
  box-shadow: 0px 3px 2px 0px #006699;
  display: none;
  right: 10px;
  top: 10px;
}
.button:before {
  background: #006699;
}
.image {
  composes: button-image from '../../../../../../mixins/button.module.css';
}

@media all and (max-width: 640px){
  .button {
    display: flex;
  }
}