.mapSection {
  composes: bg-color--white from '../../mixins/color.module.css';
  composes: position--fixed from '../../mixins/position.module.css';
  composes: width--fluid from '../../mixins/width.module.css';
  bottom: 0;
  height: 100%;
  left: 0;
  padding-bottom: 45px;
  right: 0;
  top: 0;
  z-index: 5;
}
.mapWrapper {
  composes: width--fluid from '../../mixins/width.module.css';
  height: 100%;
}

@media all and (max-width: 480px){
  .mapSection {
    padding-bottom: 35px;
  }
}