.buttons {
  composes: position--absolute from '../../../../../mixins/position.module.css';
  bottom: 70px;
  left: 10px;
}
.button {
  composes: button--round from '../../../../../mixins/button.module.css';
  composes: bg-color--main from '../../../../../mixins/color.module.css';
  composes: position--relative from '../../../../../mixins/position.module.css';
  composes: font-weight--800 from '../../../../../mixins/typography.module.css';
  box-shadow: 0px 3px 2px 0px #006699;
  flex-direction: column;
  font-size: 4.8rem;
  line-height: 55px;
}
.button:before {
  background: #006699;
}
.button:hover {
  box-shadow: 0px 0px 2px 0px #2788ba;
}
.button span {
  flex: 0 0 50px;
  max-height: 50px;
}
.zoomIn {
  composes: button;
  margin-bottom: 10px;
}

@media all and (max-width: 480px){
  .button {
    height: 50px;
    font-size: 3.6rem;
    width: 50px;
  }
  .button:before {
    height: 100px;
    left: -25px;
    top: 75px;
    width: 100px;
  }
}