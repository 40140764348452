.wrapper {
  composes: bg-color--white from '../../mixins/color.module.css';
  composes: display--flex from '../../mixins/display.module.css';
  composes: position--fixed from '../../mixins/position.module.css';
  align-items: flex-start;
  bottom: 0;
  justify-content: space-between;
  left: 0;
  line-height: 1em;
  padding: 20px;
  right: 0;
  z-index: 999;
}

.content {
  padding-right: 20px;
}

.link {
  color: #006699;
}
.link:hover {
  text-decoration: underline;
}

.button {
  composes: bg-color--main from '../../mixins/color.module.css';
  composes: color--white from '../../mixins/color.module.css';
  composes: position--relative from '../../mixins/position.module.css';
  composes: cursor--pointer from '../../mixins/misc.module.css';
  composes: font-weight--600 from '../../mixins/typography.module.css';
  border-radius: 2px;
  box-shadow: 0px 3px 2px 0px #006699;
  overflow: hidden;
  padding: 10px;
  text-align: center;
  transition: box-shadow .2s ease;
  z-index: 10;
}
.button:before {
  background: #006699;
  border-radius: 2px;
  content: '';
  display: block;
  height: 140px;
  left: 0;
  position: absolute;
  top: 50px;
  transition: top .2s ease;
  width: 100%;
  z-index: -1;
}
.button:hover {
  box-shadow: 0px 0px 2px 0px #2788ba;
}
.button:hover:before {
  top: 20px;
}

@media all and (max-width: 768px){
  .wrapper {
    display: block;
  }
  .content {
    font-size: 1.4rem;
    line-height: 1.42em;
    margin-bottom: 20px;
    padding-right: 0;
    text-align: justify;
  }
}