@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/LatoLatin-Regular.woff2') format('woff2'),
       url('./assets/fonts/LatoLatin-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/LatoLatin-Semibold.woff2') format('woff2'),
       url('./assets/fonts/LatoLatin-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/LatoLatin-Heavy.woff2') format('woff2'),
       url('./assets/fonts/LatoLatin-Heavy.woff') format('woff');
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}