.header {
  composes: bg-color--white from '../../mixins/color.module.css';
  composes: position--fixed from '../../mixins/position.module.css';
  border-bottom-right-radius: 20px;
  box-shadow: 0px 3px 1px 0px rgba(0,0,0,.3);
  left: 0;
  padding: 10px 20px 15px 15px;
  top: 0;
  z-index: 10;
}

@media all and (max-width: 640px){
  .header {
    padding: 10px 15px 10px 10px;
  }
}