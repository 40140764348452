.button {
  composes: button--round from '../../../../../mixins/button.module.css';
  background: #368636;
  box-shadow: 0px 3px 2px 0px #1d502c;
  right: 10px;
  bottom: 70px;
}
.button:before {
  background: #1d502c;
}
.button:hover {
  box-shadow: 0px 0px 2px 0px #368636;
}

.image {
  composes: button-image from '../../../../../mixins/button.module.css';
}