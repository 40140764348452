.logo {
  composes: display--block from '../../../mixins/display.module.css';
  max-width: 160px;
}
.image {
  composes: display--block from '../../../mixins/display.module.css';
  composes: width--fluid from '../../../mixins/width.module.css';
  height: auto;
}

@media all and (max-width: 640px){
  .logo {
    max-width: 140px;
  }
}