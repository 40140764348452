.overlay {
  composes: display--flex from '../../mixins/display.module.css';
  composes: position--fixed from '../../mixins/position.module.css';
  align-items: flex-start;
  background: rgba(255,255,255,.75);
  bottom: 0;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
}
.container {
  composes: bg-color--white from '../../mixins/color.module.css';
  composes: position--relative from '../../mixins/position.module.css';
  composes: width--fluid from '../../mixins/width.module.css';
  border-radius: 5px;
  box-shadow: 0px 3px 1px 0px rgba(0,0,0,.3);
  flex: 0 1 640px;
  padding: 20px;
  max-width: 640px;
  top: 100px;
}
.title {
  composes: font-weight--800 from '../../mixins/typography.module.css';
  border-bottom: 2px solid #ddd;
  font-size: 2rem;
  margin-bottom: 10px;
  padding: 5px 0;
  text-align: center;
  text-transform: uppercase;
}
.description {
  font-size: 1.4rem;
  line-height: 1.42em;
  margin-bottom: 10px;
  text-align: justify;
}
.textarea {
  composes: width--fluid from '../../mixins/width.module.css';
  composes: font-family from '../../mixins/typography.module.css';
  border: 0;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,.3);
  font-size: 1.6rem;
  height: 320px;
  margin-bottom: 10px;
  padding: 10px;
}
.button {
  composes: button from '../../mixins/button.module.css';
  composes: bg-color--main from '../../mixins/color.module.css';
  composes: font-weight--600 from '../../mixins/typography.module.css';
  composes: width--fluid from '../../mixins/width.module.css';
  border-radius: 3px;
  box-shadow: 0px 3px 2px 0px #006699;
  padding: 10px;
  transition: all .2s ease;
}
.button:hover {
  background: #fff;
  box-shadow: 0px 0px 2px 0px #2788ba;
  color: #2788ba;
}
.error {
  color: #b80b0b;
  font-size: 1.4rem;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.success {
  composes: font-weight--800 from '../../mixins/typography.module.css';
  color: #3b5c06;
  font-size: 2rem;
  line-height: 1.2em;
  text-align: center;
  text-transform: uppercase;
}

@media all and (max-width: 960px){
  .textarea {
    height: 240px;
  }
}

@media all and (max-width: 640px){
  .container {
    top: 60px;
  }
  .title {
    font-size: 1.8rem;
  }
}

@media all and (max-width: 480px){
  .container {
    padding: 10px 20px;
  }
  .title {
    border-bottom: 0;
    font-size: 1.4rem;
  }
  .description {
    font-size: 1.2rem;
  }
  .textarea {
    font-size: 1.4rem;
    height: 180px;
  }
}