.button {
  composes: cursor--pointer from './misc.module.css';
  composes: color--white from './color.module.css';
  text-align: center;
}

.button--round {
  composes: button;
  composes: display--flex from './display.module.css';
  composes: position--absolute from './position.module.css';
  align-items: center;
  border-radius: 50%;
  height: 70px;
  justify-content: center;
  overflow: hidden;
  transition: box-shadow .2s ease;
  width: 70px;
  z-index: 10;
}

.button--round::before {
  border-radius: 20px;
  content: '';
  display: block;
  height: 140px;
  left: -35px;
  position: absolute;
  top: 100px;
  transform: rotate(45deg);
  transition: top .2s ease;
  width: 140px;
  z-index: -1;
}

.button--round:hover::before {
  top: 50px;
}

.button-image {
  composes: display--block from './display.module.css';
  height: 40px;
  margin-top: -5px;
  width: 40px;
}