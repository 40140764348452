.font-weight--600 {
  font-weight: 600;
}

.font-weight--800 {
  font-weight: 800;
}

.font-family {
  font-family: 'Lato', sans-serif;
}