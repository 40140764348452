.document {
  composes: width--fluid from '../../mixins/width.module.css';
  max-width: 960px;
  margin: 100px auto;
  padding: 10px 10px 100px;
}
.title {
  composes: font-weight--800 from '../../mixins/typography.module.css';
  border-bottom: 1px solid #ddd;
  font-size: 3.2rem;
  letter-spacing: -1px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.content {
  line-height: 1.42em;
  padding: 10px 0;
}
.content h2 {
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: -.5px;
  line-height: 2em;
  margin-bottom: 10px;
}
.content h3 {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 1.42em;
  margin-bottom: 10px;
}
.content p {
  color: #333;
  margin-bottom: 10px;
}
.content ul {
  background: #eee;
  padding: 5px 10px;
  list-style: square inside;
  margin-bottom: 20px;
}
.content a {
  color: #006699;
  text-decoration: underline;
}
.content a:hover {
  text-decoration: none;
}
.content hr {
  border-top: 1px solid #eee;
}