.display--flex {
  display: flex;
}

.display--block {
  display: block;
}

.display--inline-block {
  display: inline-block;
}