.position--absolute {
  position: absolute;
}

.position--relative {
  position: relative;
}

.position--fixed {
  position: fixed;
}