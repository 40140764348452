.overlay-enter {
  opacity: 0;
}
.overlay-enter-active {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}
.overlay-exit {
  opacity: 1;
}
.overlay-exit-active {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.popup-enter {
  opacity: 0;
  transform: translateY(-300px);
}
.popup-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}
.popup-exit {
  opacity: 1;
  transform: translateY(0px);
}
.popup-exit-active {
  opacity: 0;
  transform: translateY(-300px);
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}